<template>
  <div class="login">
    <div class="centerBox">
      <div class="leftBox">
        <img src="../assets/login-left.png" alt="" />
      </div>
      <div class="rightBox">
        <div class="loginBox">
          <div class="loginTitle">
            <div class="logo">
              <img src="../assets/logo.png" alt="" />
            </div>
            <div class="titleText">热实验集群测控云平台</div>
          </div>
          <div class="loginAccount">
            <el-input
              v-model="loginFrom.assets"
              prefix-icon="el-icon-user-solid"
              placeholder="请输入用户名"
            ></el-input>
          </div>
          <div class="loginPassword">
            <el-input
              type="password"
              v-model="loginFrom.password"
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
            ></el-input>
          </div>
          <div class="loginCheckbox">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
          </div>
          <div class="loginBtn">
            <el-button class="btn" type="primary" @click="loginFn"
              >登录</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      loginFrom: {
        assets: "",
        password: "",
      },
      checked: true,
    };
  },
  methods: {
    loginFn() {
      if (
        this.loginFrom.assets === "admin" &&
        this.loginFrom.password === "1234"
      ) {
        this.$router.push("/home");
      } else {
        this.$message.error("账号或密码错误");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  height: 100vh;
  background: url(../assets/login-bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .centerBox {
    height: 387px;
    min-width: 1000px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 2px 12px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    overflow: hidden;
    .leftBox {
      width: 60%;
      img {
        width: 100%;
      }
    }
    .rightBox {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .loginBox {
        width: 80%;
        .loginTitle {
          display: flex;
          height: 50px;
          line-height: 50px;
          justify-content: center;
          margin-bottom: 40px;
          .logo {
            width: 50px;
            height: 50px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .titleText {
            font-size: 18px;
            font-weight: 700;
            color: #3176bb;
          }
        }
        .loginAccount {
          margin-bottom: 15px;
        }
        .loginPassword {
          margin-bottom: 15px;
        }
        .loginCheckbox {
          margin-bottom: 15px;
        }
        .loginBtn {
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
